export const state = () => ({
    fewoDatas: {
        "landhaus": [
            {
                "caption": "Eingang zum Ahrenshooper Landhaus vom Hafenweg",
                "img": 'landhaus/haus_DSC_0006.jpeg',
                "thumb": require('~/assets/img/landhaus/haus_DSC_0006.jpeg?resize&size=768').src,
                "src": require('~/assets/img/landhaus/haus_DSC_0006.jpeg?resize').src
            },
            {
                "caption": "Garten mit altem Brunnen",
                "img": 'landhaus/haus_DSC_0001.jpeg',
                "thumb": require('~/assets/img/landhaus/haus_DSC_0001.jpeg?resize&size=768').src,
                "src": require('~/assets/img/landhaus/haus_DSC_0001.jpeg?resize').src
            },
            {
                "caption": "Nordseite mit Hauseingang",
                "img": 'landhaus/haus_DSC_0007.jpeg',
                "thumb": require('~/assets/img/landhaus/haus_DSC_0007.jpeg?resize&size=768').src,
                "src": require('~/assets/img/landhaus/haus_DSC_0007.jpeg?resize').src
            },
            {
                "caption": "Südseite mit Wiese",
                "img": 'landhaus/haus_DSC_0012.jpeg',
                "thumb": require('~/assets/img/landhaus/haus_DSC_0012.jpeg?resize&size=768').src,
                "src": require('~/assets/img/landhaus/haus_DSC_0012.jpeg?resize').src
            },
            {
                "caption": "Bepflanzung rund um die westseitige Terasse",
                "img": 'landhaus/haus_DSC_0019.jpeg',
                "thumb": require('~/assets/img/landhaus/haus_DSC_0019.jpeg?resize&size=768').src,
                "src": require('~/assets/img/landhaus/haus_DSC_0019.jpeg?resize').src
            },
            {
                "caption": "Ahrenshooper Landhaus",
                "img": 'landhaus/haus_DSC_0023.jpeg',
                "thumb": require('~/assets/img/landhaus/haus_DSC_0023.jpeg?resize&size=768').src,
                "src": require('~/assets/img/landhaus/haus_DSC_0023.jpeg?resize').src
            },
            {
                "caption": "Ahrenshooper Landhaus",
                "img": 'landhaus/haus_DSC_0034.jpeg',
                "thumb": require('~/assets/img/landhaus/haus_DSC_0034.jpeg?resize&size=768').src,
                "src": require('~/assets/img/landhaus/haus_DSC_0034.jpeg?resize').src
            }
        ],
        "fewo": [
            {
                "caption": "Wohnzimmer",
                "img": 'fewo/wz_DSC_0044.jpeg',
                "thumb": require('~/assets/img/fewo/wz_DSC_0044.jpeg?resize&size=768').src,
                "src": require('~/assets/img/fewo/wz_DSC_0044.jpeg?resize').src
            },
            {
                "caption": "Wohnzimmer",
                "img": 'fewo/wz_DSC_0049.jpeg',
                "thumb": require('~/assets/img/fewo/wz_DSC_0049.jpeg?resize&size=768').src,
                "src": require('~/assets/img/fewo/wz_DSC_0049.jpeg?resize').src
            },
            {
                "caption": "Wohnzimmer",
                "img": 'fewo/wz_DSC_0068.jpeg',
                "thumb": require('~/assets/img/fewo/wz_DSC_0068.jpeg?resize&size=768').src,
                "src": require('~/assets/img/fewo/wz_DSC_0068.jpeg?resize').src
            },
            {
                "caption": "Wohnzimmer",
                "img": 'fewo/wz_DSC_0064.jpeg',
                "thumb": require('~/assets/img/fewo/wz_DSC_0064.jpeg?resize&size=768').src,
                "src": require('~/assets/img/fewo/wz_DSC_0064.jpeg?resize').src
            },
            {
                "caption": "Wohnzimmer",
                "img": 'fewo/wz_DSC_0074.jpeg',
                "thumb": require('~/assets/img/fewo/wz_DSC_0074.jpeg?resize&size=768').src,
                "src": require('~/assets/img/fewo/wz_DSC_0074.jpeg?resize').src
            },
            {
                "caption": "Badezimmer",
                "img": 'fewo/bad_DSC_0015.jpeg',
                "thumb": require('~/assets/img/fewo/bad_DSC_0015.jpeg?resize&size=768').src,
                "src": require('~/assets/img/fewo/bad_DSC_0015.jpeg?resize').src
            },
            {
                "caption": "Badezimmer",
                "img": 'fewo/bad_DSC_0018.jpeg',
                "thumb": require('~/assets/img/fewo/bad_DSC_0018.jpeg?resize&size=768').src,
                "src": require('~/assets/img/fewo/bad_DSC_0018.jpeg?resize').src
            },
            {
                "caption": "Schlafzimmer",
                "img": 'fewo/sz_DSC_0080.jpeg',
                "thumb": require('~/assets/img/fewo/sz_DSC_0080.jpeg?resize&size=768').src,
                "src": require('~/assets/img/fewo/sz_DSC_0080.jpeg?resize').src
            },
            {
                "caption": "Schlafzimmer",
                "img": 'fewo/sz_DSC_0083.jpeg',
                "thumb": require('~/assets/img/fewo/sz_DSC_0083.jpeg?resize&size=768').src,
                "src": require('~/assets/img/fewo/sz_DSC_0083.jpeg?resize').src
            },
            {
                "caption": "Schlafzimmer",
                "img": 'fewo/sz_DSC_0098.jpeg',
                "thumb": require('~/assets/img/fewo/sz_DSC_0098.jpeg?resize&size=768').src,
                "src": require('~/assets/img/fewo/sz_DSC_0098.jpeg?resize').src
            },
        ],
        "impressum": [
            {
                "caption": "Garten mit altem Brunnen",
                "img": 'landhaus/haus_DSC_0001.jpeg',
                "thumb": require('~/assets/img/landhaus/haus_DSC_0001.jpeg?resize&size=768').src,
                "src": require('~/assets/img/landhaus/haus_DSC_0001.jpeg?resize').src
            }
        ],
    }
})
